@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url('Inter-Regular.woff2?v=3.11') format('woff2'),
    url('Inter-Regular.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url('Inter-Italic.woff2?v=3.11') format('woff2'),
    url('Inter-Italic.woff?v=3.11') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url('Inter-Bold.woff2?v=3.11') format('woff2'),
    url('Inter-Bold.woff?v=3.11') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: url('Inter-BoldItalic.woff2?v=3.11') format('woff2'),
    url('Inter-BoldItalic.woff?v=3.11') format('woff');
}
