@import './normalize.css';
@import './fonts/inter.css';

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #0e1317;
  font-family: Inter, Helvetica, 'Helvetica Neue', Arial;
  font-size: 1.25em;
  line-height: 1.3;
  margin: 0;
  padding: 0 8vw;
}

@media (prefers-color-scheme: dark) {
  html {
    background: #192026;
    color: white;
  }
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 1em;
  }
}

body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 820px;
  min-height: 100vh;
  padding-right: 100px;
}

a {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-left: -0.2em;
  margin-right: -0.2em;
  padding: 0.2em 0.2em;
  position: relative;
  text-decoration: none;
  transition: ease-in-out 0.2s all;
}

a::after {
  border-bottom: solid 1px;
  bottom: 0.15em;
  content: '';
  left: 0.2em;
  position: absolute;
  right: 0.2em;
  transition: ease-in-out 0.15s opacity;
}

a:hover {
  background: rgba(27, 178, 242, 0.32);
}

a:hover::after {
  opacity: 0;
}

.name {
  animation: 0.5s ease-in-out 0.2s fadeInDown forwards;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  opacity: 0;
  padding-top: 4em;
}

.title {
  animation: 0.6s ease-in-out fadeInDown forwards;
  flex: 1;
  font-size: 4em;
  font-weight: 700;
  line-height: 1.1;
  margin-left: -0.04em; /* optical alignment */
  margin-top: 0.5em;
  opacity: 0;
  text-rendering: optimizeLegibility;
}

.cv {
  line-height: 1.6;
  list-style-type: none;
  max-width: 21em;
  padding-bottom: 2em;
  padding-left: 0;
}

.cv-item {
  animation: 0.5s ease-in-out 0.3s fadeInDown forwards;
  margin-bottom: 3em;
  opacity: 0;
}

.cv-item:nth-child(2) {
  animation-delay: 0.4s;
}

.cv-item:nth-child(3) {
  animation-delay: 0.5s;
}

@media screen and (max-width: 600px) {
  .name {
    padding-top: 8vw;
  }

  body {
    padding-right: 10px;
  }

  .title {
    font-size: 3.4em;
  }
}
